var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Quill from 'quill';
import QuillMarkdown from 'quilljs-markdown';
export var AdminEditor;
(function (AdminEditor) {
    let editor = null;
    function SaveContent() {
        saveContent();
    }
    AdminEditor.SaveContent = SaveContent;
    function EditContent(editorId) {
        editContent(editorId);
    }
    AdminEditor.EditContent = EditContent;
    function saveContent() {
        const htmlContent = editor.root.innerHTML;
        const fetchUrl = window.location.href;
        fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content: htmlContent })
        })
            .then(data => window.location.reload())
            .catch((error) => console.error('Error:', error));
    }
    let BlockEmbed = Quill.import('blots/block/embed');
    class CarouselBlot extends BlockEmbed {
        static create(value) {
            let node = super.create();
            node.setAttribute('class', 'carousel-placeholder');
            return node;
        }
        static value(node) {
            return {
                placeholder: node.getAttribute('class'),
            };
        }
    }
    CarouselBlot.blotName = 'carousel';
    CarouselBlot.tagName = 'div';
    Quill.register(CarouselBlot);
    var toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image', 'uhe-carousel'],
        ['editor-save', 'editor-exit']
    ];
    const buttonTitles = {
        'ql-bold': 'Bold',
        'ql-italic': 'Italic',
        'ql-underline': 'Underline',
        'ql-strike': 'Strike',
        'ql-blockquote': 'Blockquote',
        'ql-list[value="ordered"]': 'Ordered List',
        'ql-list[value="bullet"]': 'Bullet List',
        'ql-color': 'Text Color',
        'ql-background': 'Background Color',
        'ql-align': 'Text Alignment',
        'ql-clean': 'Remove Formatting',
        'ql-link': 'Insert Link',
        'ql-image': 'Insert Image',
        'ql-uhe-carousel': 'Insert Carousel',
    };
    function doesCustomBlotExist(quill, blotName) {
        let exists = false;
        const blotClass = Quill.import('formats/' + blotName);
        quill.getContents().ops.some(op => {
            if (op.insert && typeof op.insert === 'object' && op.insert[blotClass.blotName]) {
                exists = true;
                return true; // Break the loop once found
            }
            return false;
        });
        return exists;
    }
    let insertedImages = [];
    function selectLocalImage() {
        return __awaiter(this, void 0, void 0, function* () {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.click();
            input.onchange = () => {
                const file = input.files[0];
                // Use FormData to send the image file
                const formData = new FormData();
                formData.append('ImageFiles', file);
                // POST the image to your server
                fetch('/image', {
                    method: 'POST',
                    body: formData
                })
                    .then((response) => __awaiter(this, void 0, void 0, function* () {
                    if (response.ok) {
                        var resj = yield response.json();
                        return resj;
                    }
                }))
                    .then(result => {
                    const range = editor.getSelection();
                    editor.insertEmbed(range.index, 'image', result.imageUrl);
                    insertedImages.push(result.imageUrl);
                })
                    .catch(error => {
                    console.error('Image upload error:', error);
                });
            };
        });
    }
    function editContent(editorId) {
        const editButton = document.getElementById('updateContentEdit');
        if (editButton instanceof HTMLButtonElement) {
            editButton.disabled = true;
        }
        const parentElement = document.getElementById(editorId);
        const childElement = parentElement.querySelector('#carousel-wrapper');
        if (childElement) {
            const newElement = document.createElement('div');
            newElement.classList.add('carousel-x');
            childElement.replaceWith(newElement);
        }
        editor = new Quill('#' + editorId, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: 'snow' // or 'snow'
        });
        const markdownOptions = {
        /**
         ignoreTags: [ 'pre', 'strikethrough'], // @option - if you need to ignore some tags.
         
         tags: { // @option if you need to change for trigger pattern for some tags.
          blockquote: {
            pattern: /^(\|){1,6}\s/g,
          },
          bold: {
            pattern:  /^(\|){1,6}\s/g,
          },
          italic: {
            pattern: /(\_){1}(.+?)(?:\1){1}/g,
          },
        },
        */
        };
        const quillMarkdown = new QuillMarkdown(editor, markdownOptions);
        let toolbar = editor.getModule('toolbar');
        toolbar.addHandler('image', selectLocalImage); // Your existing image handler
        Object.keys(buttonTitles).forEach(selector => {
            const button = document.querySelector(`.ql-toolbar .${selector}`);
            if (button) {
                button.title = buttonTitles[selector];
            }
        });
        const carouselFlagElement = document.getElementById('can_have_carousel');
        if (carouselFlagElement instanceof HTMLInputElement) {
            const canHaveCarousel = carouselFlagElement.value === 'true';
            console.log(canHaveCarousel);
            if (!canHaveCarousel) {
                const customButton = document.querySelector('.ql-uhe-carousel');
                if (customButton instanceof HTMLButtonElement) {
                    customButton.style.display = 'none';
                    // customButton.disabled = true;
                    // customButton.title = 'Carousel is not allowed';
                }
            }
        }
        const customButton = document.querySelector('.ql-uhe-carousel');
        if (customButton) {
            customButton.innerHTML = '<i class="fa-solid fa-photo-film"></i>'; // Set custom button text or icon
            customButton.addEventListener('click', function () {
                if (doesCustomBlotExist(editor, 'carousel')) {
                    alert('Only one carousel is allowed.');
                }
                else {
                    let range = editor.getSelection();
                    if (range) {
                        editor.insertEmbed(range.index, 'carousel', '');
                        editor.setSelection(range.index + 1, 0);
                    }
                }
            });
        }
        const editorSaveButton = document.querySelector('.ql-editor-save');
        if (editorSaveButton) {
            editorSaveButton.innerHTML = '<i class="fa-solid fa-floppy-disk"></i>'; // Set custom button text or icon
            editorSaveButton.setAttribute('title', 'Save and Exit');
            editorSaveButton.addEventListener('click', function () {
                saveContent();
            });
        }
        const editorCloseButton = document.querySelector('.ql-editor-exit');
        if (editorCloseButton) {
            editorCloseButton.innerHTML = '<i class="fa-solid fa-square-xmark"></i>'; // Set custom button text or icon
            editorCloseButton.setAttribute('title', 'Discard Changes and Exit');
            editorCloseButton.addEventListener('click', function () {
                var resposne = confirm('Are you sure you want to exit? Any unsaved changes will be lost.');
                if (resposne) {
                    window.location.reload();
                }
            });
        }
    }
    ;
})(AdminEditor || (AdminEditor = {}));
